import React, {
  createContext,
  useEffect,
  useRef,
  useState,
  useContext,
  useCallback,
} from "react";
import { io } from "socket.io-client";
import { AuthContext } from "./authContext";
import { makeRequest } from "../axios";
// import { useQueryClient } from "@tanstack/react-query";

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const { loggedInUser, userDetails, authToken } = useContext(AuthContext);

  const socket = useRef(null);
  const [onlineUsers, setOnlineUsers] = useState([]);

  // Listen for the "lastSeen" event
  const [disconnectedUsers, setDisconnectedUsers] = useState([]);
  // console.log("disconnectedUsers:", disconnectedUsers);
  // console.log("onlineUsers:", onlineUsers);

  const SOCKETURL = process.env.REACT_APP_SOCKET_URL;
  const userName = userDetails?.fullName
    ? userDetails?.fullName
    : userDetails?.username;

  const connectSocket = useCallback(() => {
    if (!socket.current) {
      socket.current = io(SOCKETURL);

      socket.current.on("connect", () => {
        // console.log("Connected to socket server");
        if (userDetails) {
          socket.current.emit("addUser", userDetails?.userId, userName);
        }
      });

      socket.current.on("getUsers", (users) => {
        const onlineUserIds = users.map((user) => user.userId);
        setOnlineUsers(onlineUserIds);
      });

      socket.current.on("disconnect", async () => {
        // console.log("Disconnected from socket server");
        if (loggedInUser !== null)
          try {
            await makeRequest.put(
              `${process.env.REACT_APP_URL}/users/loggedOutTime`,
              { userId: userDetails.userId },
              {
                headers: {
                  Authorization: `Bearer ${authToken}`,
                },
              }
            );
          } catch (error) {
            // console.log(error);
          }
      });

      socket.current.on("disconnectedUsers", (data) => {
      
        setDisconnectedUsers(data); // Set the data to your state
      });
    }
  }, [userDetails, userName, authToken, SOCKETURL, loggedInUser]);

  const disconnectSocket = useCallback(() => {
    if (socket.current) {
      socket.current.disconnect();
      socket.current = null;
    }
  }, []);

  const checkForUpdates = async () => {
    // Define the latest version of the application

    const latestVersion = "1.0.2"; // Update this with the latest version of your application

    // Retrieve the stored version from Local Storage
    const storedVersion = localStorage.getItem("appVersion");

    // Compare the latest version with the stored version
    if (latestVersion !== storedVersion) {
      // If a newer version is available, update the stored version
      localStorage.setItem("appVersion", latestVersion);

      // Reload the page to load the latest version
      window.location.reload();
    }
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        connectSocket();
        checkForUpdates();
      } else {
        setTimeout(() => {
          disconnectSocket();
        }, 3000);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Initial connection
    connectSocket();

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      disconnectSocket();
    };
  }, [connectSocket, disconnectSocket, userDetails.userId]);

  useEffect(() => {
    if (socket.current && userDetails) {
      socket.current.emit("addUser", userDetails?.userId, userName);
    }
  }, [userDetails, userName]);

  

  

  return (
    <SocketContext.Provider
      value={{ socketConnect: socket.current, onlineUsers, disconnectedUsers }}
    >
      {children}
    </SocketContext.Provider>
  );
};
